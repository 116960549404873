import gifUmaVidaFinanceiraMobile from './gif/gif-uma-vida-financeira-mobile.gif'
import gifUmaVidaFinanceiraDesktop from './gif/gif-uma-vida-financeira-desktop.gif'

const backgrounds = {
  backgroundHomeSection1: {
    mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/mobile-oc/image.webp',
    desktop: 'https://central-imagens.bancointer.com.br/images-without-small-versions/home-interco-oc/image.webp',
  },
  backgroundHomeSection2EN: {
    mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-us-en/image.webp',
    desktop: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-us-en/image.webp',
  },
  backgroundHomeSection2PT: {
    mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-us-pt/image.webp',
    desktop: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-us-pt/image.webp',
  },
  backgroundHomeSection3: {
    mobile: gifUmaVidaFinanceiraMobile,
    desktop: gifUmaVidaFinanceiraDesktop,
  },
  backgroundHomeSection4: {
    mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-us-mobile/image.webp',
    desktop: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-home-us/image.webp',
  },
  backgroundHomeSection5: {
    mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/360-hero-us/image.webp',
    desktop: 'https://central-imagens.bancointer.com.br/images-without-small-versions/hero-us-1440/image.webp',
  },
}

export default backgrounds
