import React, { useState, useLayoutEffect } from 'react'

import pageContext from './pageContext.json'
import { Wrapper } from '../../privacidade-de-dados/style'

import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'

import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import QRCodeUSModal from '../../en/us/assets/images/qr-code-us-modal.png'
import SlideScrollSections from '../../en/us/components/SlideScrollSections/_index'

export const Sections = ({ lang }: {lang: string}) => {
  return (
    <>
      <SlideScrollSections lang={lang} slugEN={pageContext.pageEN} slugPT={pageContext.slug} />
    </>
  )
}

const Us = ({ lang }: {lang: string}) => {
  const [ isMobile, setIsMobile ] = useState(true)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ simulatorModalOpen, setSimulatorModalOpen ] = useState(false)

  const domReady = useDomReady()
  const width = useWidth(300)

  const WIDTH_MD = 768

  const hubModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Download and open a free account'
        instructions=''
        qrCode={QRCodeUSModal}
      />
    </Modal>
  )

  const usENRegister = () => {
    setSimulatorModalOpen(!simulatorModalOpen)
  }

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width, isMobile ])

  return (
    <Wrapper>
      {hubModal}
      <Layout handleHeaderOpenAccount={() => usENRegister()} pageContext={pageContext}>
        <Sections lang={lang} />
      </Layout>
    </Wrapper>
  )
}

Us.defaultProps = {
  lang: 'pt',
}

export default Us
