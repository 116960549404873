import React from 'react'
import * as S from './style'
import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import ImageWebp from 'src/components/ImageWebp'

import textPT from '../../assets/data/translate/o-super-app/text-pt.json'
import textEN from '../../assets/data/translate/o-super-app/text-en.json'
interface IOSuperAppProps {
  setIsOpen: Function;
  lang: string;
}

const OSuperApp = React.forwardRef((props, ref) => {
  const { setIsOpen, lang }: IOSuperAppProps = props
  const { title, CTA }: ISlideScrollSections = lang === 'pt' ? textPT : textEN

  return (
    <S.ModelsSectionBackground
      key='TESTE 2'
      className='colored'
      ref={ref}
      role='img'
      aria-label='Tela Super App'
    >
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 px-md-0'>
            <div>
              <S.TitleExtraLarge
                as='h2' className='fw-400 f-sora text-grayscale--500 text-center'
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <div className='d-md-none text-center'>
                <S.CTA
                  as='a'
                  className='text-grayscale--500'
                  href='https://bancointer.go.link/globalAccount/remittance?adj_t=188p5dy7'
                  target='blank'
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color='#161616' className='ml-2' />
                </S.CTA>
              </div>
              <div className='text-center d-none d-md-block'>
                <S.CTA as='button' className='text-grayscale--500 text-center' onClick={() => setIsOpen(true)}>
                  {CTA.text}
                  <ArrowRight height={24} width={24} color='#161616' className='ml-2' />
                </S.CTA>
              </div>
            </div>
          </div>

          <div className='d-none d-md-flex align-items-md-end mx-auto'>
            <ImageWebp
              pathSrc={
                lang === 'pt'
                ? 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-desk-oc-pt/image.webp'
                : 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-interco-desktop/image.webp'
              }
              altDescription='Orlando City Pride'
              arrayNumbers={[ 0, 500, 550, 550, 850 ]}
              arrayNumbersHeight={[ 0, 400, 450, 500, 650 ]}
            />
          </div>
        </div>
      </div>

      <div className='d-flex mobile-container-img'>
        <div className='d-md-none'>
          <ImageWebp
            pathSrc={lang === 'pt'
            ? 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-desk-oc-pt/image.webp'
            : 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra-mobile-oc/image.webp'
          }
            altDescription='Orlando City Pride'
          />
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
})

export default OSuperApp
