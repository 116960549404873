import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { IModelsSectionBackground } from '../../types'
import { grayscale } from 'src/styles/colors'
import { sand } from 'src/styles/newColors'

export const Container = styled.div``

export const ModelsSectionBackground = styled('div')<IModelsSectionBackground>`
  height: 100vh;
  background-color: ${sand};
  position: relative;

  .mobile-container-img {
    height: calc(100vh - 196px);
    justify-content: flex-end;
    align-items: flex-end
  }

  scroll-snap-align: start;
  scroll-snap-stop: always;
  padding-top: 80px;

  @media ${device.tablet} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .row {
    @media ${device.tablet} {
      height: calc(100vh - 84px)
    }
  }
`

export const Spacer = styled.div`
  height: 15vh;
  background: ${grayscale[500]};
`

export const TitleLarge = styled.h1`
  font-size: 36px;
  line-height: 45px;

  @media ${device.tablet} {
    font-size: 36px;
    line-height: 45px;
  }
  @media ${device.desktopLG} {
    font-size: 48px;
    line-height: 60px;
  }
  @media ${device.desktopXL} {
    font-size: 72px;
    line-height: 91px;
  }
`

export const TitleExtraLarge = styled.h1`
  font-size: 2rem;
  line-height: 2.5rem;

  @media ${device.tablet} {
    font-size: 2.25rem;
    line-height: 2.8125rem;
  }
  @media ${device.desktopLG} {
    font-size: 3rem;
    line-height: 3.75rem;
  }
  @media ${device.desktopXL} {
    font-size: 4.5rem;
    line-height: 5.6875rem;
  }
  @media ${device.desktopXXXL} {
    font-size: 5.625rem;
    line-height: 7.5rem;
  }
`

export const CTA = styled(Link)`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  @media ${device.desktopXL} {
    font-size: 24px;
    line-height: 30px;
  }
  @media ${device.desktopXXXL} {
    font-size: 32px;
    line-height: 38px;
  }
`

export const ContainerVideo = styled.div`
  position: relative;
`

export const AbsoluteBlockText = styled.div`
  position: absolute;
  top: 50%;
  left: 41%;
  transform: translate(-50%, -50%);
`
