import React, { useContext, useState } from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import useDomReady from 'src/hooks/window/useDomReady'
import { GlobalContext, IGlobalContext } from 'src/context/GlobalContext'
import { WIDTH_LG } from 'src/styles/breakpoints'

import PatrocinadorOficial from './sections/patrocinador-oficial/_index'
import OSuperApp from './sections/o-super-app/_index'
import GlobalPraticoSeguro from './sections/global-pratico-seguro/_index'
import FinanciarUmImovel from './sections/financiar-um-imovel/_index'
import PlataformaDeInvestimentos from './sections/plataforma-de-investimentos/_index'
import HomeFooter from './sections/footer/_index'

import QrCodeAbrirConta from '../../assets/images/qr-code-abrir-conta.png'
import * as S from './style'

const SlideScrollSections = ({ lang, slugPT, slugEN }: {lang: string; slugPT: string; slugEN: string}) => {
  const listRef = React.useRef<HTMLDivElement>(null)
  const { setHeaderColorContrast, setScrollTopHomeValue }: IGlobalContext = useContext(GlobalContext)
  const windowWidth = useWidth()

  const oSuperAppRef = React.useRef(null)
  const globalPraticoSeguroRef = React.useRef(null)

  React.useLayoutEffect(() => {
    const handleScroll = () => {
      const oSuperApp = oSuperAppRef.current.getBoundingClientRect().top
      const globalPraticoSeguro = globalPraticoSeguroRef.current.getBoundingClientRect().top
      const scrollTopHomeValue = listRef.current?.scrollTop

      if (scrollTopHomeValue) {
        setScrollTopHomeValue(scrollTopHomeValue)
      }

      if ((globalPraticoSeguro && oSuperApp) === Number(0)) {
        setHeaderColorContrast(true)
      } else {
        setHeaderColorContrast(false)
      }
    }
    if (windowWidth >= WIDTH_LG) {
      if (listRef.current) {
        listRef.current.addEventListener('scroll', handleScroll)

        return () => {
          listRef.current && listRef.current.removeEventListener('scroll', handleScroll)
        }
      }
    }
  }, [ listRef, windowWidth ])

  const [ isOpen, setIsOpen ] = useState(false)

  const domReady = useDomReady()

  const modal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title={
          lang === 'pt'
          ? '<span class="fs-24">Agora <span class="text-orange--extra">baixe nosso app</span> <span class="d-block">e abra sua conta gratuita.</span></span>'
          : '<span class="fs-24">Download our <span class="text-orange--extra">Super App</span> <span class="d-block">and open your free account.</span></span>'
        }
        instructions={
          lang === 'pt'
          ? 'Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e clicar em ”Abrir conta completa e gratuita”. É rápido e fácil!'
          : "Open your phone's camera and point to the QR Code above. Just download the Inter app and click on “Open full and free account”. It's quick and easy!"
        }
        qrCode={QrCodeAbrirConta}
      />
    </Modal>
  )

  return (
    <S.Container ref={listRef} id='my-scroll-container'>
      {modal}
      <PatrocinadorOficial lang={lang} />
      <OSuperApp setIsOpen={setIsOpen} lang={lang} ref={oSuperAppRef} />
      <GlobalPraticoSeguro setIsOpen={setIsOpen} ref={globalPraticoSeguroRef} lang={lang} />
      <FinanciarUmImovel lang={lang} />
      <PlataformaDeInvestimentos lang={lang} />
      <HomeFooter lang={lang} slugPT={slugPT} slugEN={slugEN} />
      <S.Spacer />
    </S.Container>
  )
}

export default SlideScrollSections
