import React from 'react'
import * as S from './style'
import backgrounds from '../../assets/img/_backgrounds'
import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import { white } from 'src/styles/colors'
import textPT from '../../assets/data/translate/financiar-um-imovel/text-pt.json'
import textEN from '../../assets/data/translate/financiar-um-imovel/text-en.json'

interface IFinanciarUmImovelProps {
  lang: string;
}

const FinanciarUmImovel = ({ lang }: IFinanciarUmImovelProps) => {
  const { title, description, CTA }: ISlideScrollSections = lang === 'pt' ? textPT : textEN

  return (
    <S.ModelsSectionBackground
      key='TESTE 6'
      className='colored d-flex align-items-end align-items-md-center'
      mobileBackgroundImage={backgrounds.backgroundHomeSection4.mobile}
      backgroundImage={backgrounds.backgroundHomeSection4.desktop}
      role='img'
      aria-label='Pessoa mexendo no Super App Inter com mudança organizada'
    >
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-6'>
            <div className=''>
              <S.TitleExtraLarge as='h2' className='fw-400 f-sora text-white' dangerouslySetInnerHTML={{ __html: title }} />
              <p className='fs-20 lh-25 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 f-sora fw-400 text-white my-3' dangerouslySetInnerHTML={{ __html: description }} />
              <div>
                <S.CTA
                  as='a'
                  href={CTA.link}
                  target='blank'
                  className='fs-16 lh-20 fs-xl-24 lh-xl-30 text-white'
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color={white} className='ml-2' />
                </S.CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
}

export default FinanciarUmImovel
