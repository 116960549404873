import React from 'react'
import OpenVideo from 'src/components/OpenVideo'
import Img from 'gatsby-image'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import backgrounds from '../../assets/img/_backgrounds'
import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import textPT from '../../assets/data/translate/patrocinador-oficial/text-pt.json'
import textEN from '../../assets/data/translate/patrocinador-oficial/text-en.json'
import Play from '../../assets/img/default-play-button.png'
import usePageQuery from '../../pageQuery'
import * as S from './style'

interface IHero {
  lang: string;
}

const PatrocinadorOficial = ({ lang }: IHero) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()

  const { title, description, CTA }: ISlideScrollSections = lang === 'pt' ? textPT : textEN

  return (
    <S.ModelsSectionBackground
      key='TESTE'
      className=' d-flex align-items-end align-items-md-center py-5'
      mobileBackgroundImage={backgrounds.backgroundHomeSection1.mobile}
      backgroundImage={backgrounds.backgroundHomeSection1.desktop}
      role='img'
      aria-label='Orlando City Pride'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-7 col-xl-8'>
            <S.TitleExtraLarge className='fw-400 f-sora text-white mt-xl-5' dangerouslySetInnerHTML={{ __html: title }} />
            <p className='fs-20 lh-25 fs-lg-32 lh-lg-40 fs-xl-48 lh-xl-60 font-sora fw-400 text-white my-3 description' dangerouslySetInnerHTML={{ __html: description }} />
            <div>
              <Img
                fluid={data.duoLogosOrlandoCityPride.fluid}
                alt='Logos Orlando City e Pride'
              />
            </div>
            <div className=''>
              <S.CTA
                as='a'
                className='text-white'
                href={CTA.link}
                target='blank'
              >
                {CTA.text}
                <ArrowRight height={24} width={24} color='#FFFF' className='ml-2' />
              </S.CTA>
            </div>
          </div>

          <div className='col-12 col-md-5 col-xl-4 d-flex align-items-md-center justify-content-md-start video-container mt-3 mt-md-0'>
            <OpenVideo
              link='https://www.youtube.com/embed/Gp899W8GED4?si=gwh4TuFaMHN56Dww?&autoplay=1'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_1',
                  section_name: title,
                  element_action: 'click video',
                  element_name: 'Assista ao vídeo',
                })
              }}
            >
              <img
                src={Play}
                alt='Assista ao vídeo'
                className='cursor-pointer'
                title='Assista ao vídeo'
              />
            </OpenVideo>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
}

export default PatrocinadorOficial
