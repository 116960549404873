import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      duoLogosOrlandoCityPride: imageSharp(fluid: { originalName: { regex: "/duo-logos-orlando-city-e-pride/" }}) {
        fluid(maxWidth: 424, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
