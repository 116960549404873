import React from 'react'
import * as S from './style'
import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import { grayscale } from 'src/styles/colors'
import textPT from '../../assets/data/translate/global-pratico-seguro/text-pt.json'
import textEN from '../../assets/data/translate/global-pratico-seguro/text-en.json'

import backgrounds from '../../assets/img/_backgrounds'

interface IGlobalPraticoSeguroProps {
  setIsOpen: Function;
  lang: string;
}

const GlobalPraticoSeguro = React.forwardRef((props, ref) => {
  const { setIsOpen, lang }: IGlobalPraticoSeguroProps = props
  const { title, CTA }: ISlideScrollSections = lang === 'pt' ? textPT : textEN

  return (
    <S.ModelsSectionBackground
      key='TESTE 3'
      className='colored d-flex align-items-end align-items-md-center'
      ref={ref}
      mobileBackgroundImage={backgrounds.backgroundHomeSection3.mobile}
      backgroundImage={backgrounds.backgroundHomeSection3.desktop}
      role='img'
      aria-label='Tela Super App'
    >
      <>
        <S.ContainerVideo className='container'>
          <div className='row'>
            <div className='col-12'>
              <S.TitleExtraLarge
                as='h2'
                className='fw-400 text-grayscale--500'
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <div className='d-md-none'>
                <S.CTA
                  as='a'
                  className='text-grayscale--500'
                  href='https://bancointer.go.link/globalAccount/remittance?adj_t=188p5dy7'
                  target='blank'
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color='#161616' className='ml-2' />
                </S.CTA>
              </div>
              <div className='d-none d-md-block'>
                <S.CTA
                  as='button'
                  to={CTA.link}
                  className='text-grayscale--500'
                  onClick={() => setIsOpen(true)}
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color={grayscale[500]} className='ml-2' />
                </S.CTA>
              </div>
            </div>
          </div>
        </S.ContainerVideo>
      </>
    </S.ModelsSectionBackground>
  )
})

export default GlobalPraticoSeguro
