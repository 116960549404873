import React from 'react'
import Footer from 'src/components/Us/FooterUS/_index'

import * as S from './style'

const HomeFooter = ({ lang, slugPT, slugEN }: {lang: string; slugPT: string; slugEN: string}) => {
  return (
    <S.ModelsSectionBackground className='d-flex justify-content-center align-items-end'>
      <Footer section='dobra_06' slugPT={slugPT} slugEN={slugEN} lang={lang} />
    </S.ModelsSectionBackground>
  )
}

export default HomeFooter
